import * as React from 'react'
import { StyleSheet, BackHandler, ActivityIndicator } from 'react-native'
import * as WebBrowser from 'expo-web-browser'
import AsyncStorage from '@react-native-community/async-storage'

import { Text, View, Button } from '../components/Themed'
import { StackScreenProps } from '@react-navigation/stack'

import Toast from '../toast'

import axios from 'axios'
import { RootStackParamList, PDF } from '../types'

export default function PDFScreen(props: StackScreenProps<RootStackParamList, 'PDF'>) {
	React.useMemo(() => {
		Toast.hide()
		return
	}, [])
	const { navigation, route } = props
	BackHandler.addEventListener('hardwareBackPress', () => {
		navigation.replace('Root', { screen: 'TabKukai' })
		return true
	})
	if (!route.params) {
		alert('Cannot show `PDFScreen` because of no params:`data` to process initial actions')
		navigation.replace('Root', { screen: 'TabKukai' })
		return
	}
	const kukai = route.params.data
	const [ready, setReady] = React.useState(false)
	const [loading, setLoading] = React.useState(true)
	const [data, setData] = React.useState({} as PDF)
	const tokenResponse = async () => {
		try {
			const iId = await AsyncStorage.getItem('id')
			const group = await AsyncStorage.getItem('group')
			const nowPrefix = await AsyncStorage.getItem('nowPrefix')
			const nowAt = await AsyncStorage.getItem('nowAt')
			if (nowPrefix && iId) {
				setReady(true)
				const kukais = await axios.post(`https://kukaibot.8i9.me/v1/kukai/${kukai.id}/get_pdf`, {
					i: iId,
					group: nowPrefix,
					at: nowAt,
				})
				setData(kukais.data)
				setLoading(false)
				return true
			}
			navigation.replace('Login')
			setReady(true)
		} catch {
			alert('error')
		}
	}
	if (!ready) tokenResponse()
	if (loading) {
		return (
			<View style={styles.container}>
				<ActivityIndicator size="large" color="#55cae0" />
				<Text>PDFを準備中です。しばらくお待ちください。</Text>
			</View>
		)
	}
	return (
		<View style={styles.container}>
			<Text style={styles.title}>PDF閲覧</Text>
			<Text style={styles.title}>{kukai.name}</Text>
			<Text>ダウンロードボタンは1分間有効です</Text>
			<Button title="PDFダウンロード" onPress={async() => await WebBrowser.openBrowserAsync(data.url)} materialIcon="picture-as-pdf" />
			<View style={{ height: 10 }} />
			{kukai.deletable ? <Button title="作者記入済みPDFダウンロード" onPress={async() => await WebBrowser.openBrowserAsync(data.revealed_url)}  materialIcon="picture-as-pdf" /> : null}
			<Text>現在アプリ内で閲覧/ダウンロードできるよう調節中です。</Text>
			<View style={{ height: 10 }} />
			<Button title="キャンセル" color="red" onPress={() => navigation.replace('Root', { screen: 'TabKukai' })} icon="ios-close" />
		</View>
	)
}
const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	title: {
		fontSize: 20,
		fontWeight: 'bold',
	},
	form: {
		marginVertical: 2,
		borderWidth: 1,
		width: 250,
		padding: 10,
		borderRadius: 10,
	},
})

import { StackScreenProps } from '@react-navigation/stack'
import * as React from 'react'
import { StyleSheet, TouchableOpacity, BackHandler, Switch, Platform, ActivityIndicator } from 'react-native'
import AsyncStorage from '@react-native-community/async-storage'
import { Text, View, TextInput, Button } from '../components/Themed'
import DateTimePicker from '@react-native-community/datetimepicker'
import moment from 'moment-timezone'
import 'moment/locale/ja'
import Toast from '../toast'
moment.locale('ja')
moment.tz.setDefault("Asia/Tokyo")

import { RootStackParamList } from '../types'
import axios from 'axios'
interface PostData {
	i: string
	group: string
	at: string
	name: string
	deadline?: string
}
let ios = true
if (Platform.OS === 'android') ios = false

export default function KukaiMakeScreen({ navigation }: StackScreenProps<RootStackParamList, 'KukaiMake'>) {
	React.useMemo(() => {
		Toast.hide()
		return
	}, [])
	BackHandler.addEventListener('hardwareBackPress', () => {
		navigation.replace('Root', { screen: 'TabKukai' })
		return true
	})
	const [ready, setReady] = React.useState(false)
	const [deadline, setDeadline] = React.useState(false)
	const [name, setName] = React.useState(null as null | string)
	const [date, setDate] = React.useState(null as null | Date)
	const [time, setTime] = React.useState(null as null | Date)
	const [loading, setLoading] = React.useState(false)
	const onChange = (event: any, selectedDate: null | Date) => {
		const currentDate = selectedDate || date
		setDate(currentDate)
	}
	const onChangeTime = (event: any, selectedDate: null | Date) => {
		const currentDate = selectedDate || date
		setTime(currentDate)
	}
	const init = async () => {
		try {
			setReady(true)
		} catch {}
	}
	if (!ready) init()
	// onChangeText={(text) => setGroupId(text)} value={groupId}
	if (loading) {
		return (
			<View style={styles.container}>
				<ActivityIndicator size="large" color="#55cae0" />
			</View>
		)
	}
	return (
		<View style={styles.container}>
			<Text style={styles.title}>作成</Text>
			<TextInput placeholder="名前*" style={[{borderColor: name ? 'black' : '#bf1313'}, styles.form]} onChangeText={(name) => setName(name)} />
			<View style={styles.switches}>
				<Switch
					trackColor={{ false: '#2a5c66', true: '#4598a8' }}
					thumbColor={'#4abad9'}
					ios_backgroundColor="#2a5c66"
					onValueChange={() => toggleSwitch(false)}
					value={deadline}
					style={styles.switch}
				/>
				<TouchableOpacity onPress={() => (toggleSwitch(false))} activeOpacity={0.9}>
					<Text style={ios ? {fontSize: 17, marginTop: 4} : null}>締切を指定する(プレミアム)</Text>
				</TouchableOpacity>
			</View>
			{deadline ? (
				!ios ? (
					<View>
						{!time ? <DateTimePicker testID="dateTimePicker" value={time ? time : new Date()} mode={'time'} is24Hour={true} display="default" onChange={(a, b) => onChangeTime(a, b ? b : date)} /> : null}
						{!date ? <DateTimePicker testID="dateTimePicker" value={date ? date : new Date()} mode={'date'} is24Hour={true} display="default" onChange={(a, b) => onChange(a, b ? b : date)} />: null}
						<TouchableOpacity onPress={() => (toggleSwitch(true))} activeOpacity={0.9}><Text>{moment(`${moment(date ? date : new Date()).format('YYYY-MM-DD')}T${moment(time ? time : new Date()).format('HH:mm')}:00+09:00`).format('M月D日 HH:mm')}</Text></TouchableOpacity>
					</View>
				) : (
					<View>
						<DateTimePicker testID="dateTimePicker" value={date ? date : new Date()}  mode={'datetime'} locale="ja" display="default" onChange={(a, b) => onChange(a, b ? b : date)} style={{width: 200}} />
					</View>
				)
			) : null}
			<View style={{ transform: [{ scaleX: 1.2 }, { scaleY: 1.2 }], marginTop: 30 }}>
				<Button title="作成" onPress={() => make()} icon="ios-add" />
				<View style={{ height: 20 }} />
				<Button title="キャンセル" color="red" onPress={() => navigation.replace('Root', { screen: 'TabKukai' })} icon="ios-close" />
			</View>
		</View>
	)
	function toggleSwitch(force: boolean) {
		deadline && !force ? setDeadline(false) : setDeadline(true)
		if(!deadline || force) {
			setDate(null)
			setTime(null)
		}
	}
	async function make() {
		if (!name) {
			alert('名前は必須です')
			return false
		}
		setLoading(true)
		let setEdTime: string = moment().format()
		if (Platform.OS == 'ios') {
			setEdTime = moment(date).format()
		} else {
			setEdTime = moment(`${moment(date).format('YYYY-MM-DD')}T${moment(time).format('HH:mm')}:00+09:00`).format()
		}
		const iId = await AsyncStorage.getItem('id')
		const nowPrefix = await AsyncStorage.getItem('nowPrefix')
		const nowAt = await AsyncStorage.getItem('nowAt')
		if (!iId || !nowPrefix || !nowAt) return false
		if(!name) {
			alert('句会の名前を入力してください')
			setLoading(false)
			return false
		}
		const data: PostData = {
			i: iId,
			group: nowPrefix,
			at: nowAt,
			name: name,
		}
		if (deadline) data.deadline = setEdTime
		const challenge = await axios.post('https://kukaibot.8i9.me/v1/make_kukai', data)
		if (challenge.data.success) navigation.replace('Root', { screen: 'TabKukai' })
		setDeadline(false)
		setDate(new Date())
		setTime(new Date())
		setLoading(false)
		if (challenge.data.status) alert(challenge.data.status)
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	title: {
		fontSize: 20,
		fontWeight: 'bold',
	},
	form: {
		marginVertical: 2,
		borderWidth: 1,
		width: 250,
		padding: 10,
		borderRadius: 10,
	},
	confirm: {
		marginVertical: 20,
		width: 230,
		padding: 10,
		color: '#fff',
		borderRadius: 10,
		backgroundColor: '#2d61b5',
	},
	switch: {
		transform: [{ scaleX: ios ? 1 : 1.2 }, { scaleY: ios ? 1 : 1.2 }],
		marginRight: 5,
		position: 'relative',
		top: -3,
	},
	switches: {
		marginVertical: 10,
		flex: 0,
		flexDirection: 'row',
	},
})

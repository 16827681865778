import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { ColorSchemeName } from 'react-native';

import NotFoundScreen from '../screens/NotFoundScreen';
import Login from '../screens/LoginScreen';
import Join from '../screens/JoinScreen';
import Post from '../screens/PostScreen';
import Group from '../screens/GroupScreen';
import PDF from '../screens/PDFScreen';
import Text from '../screens/TextScreen';
import OSS from '../screens/OSSScreen';
import MyHaiku from '../screens/MyHaikuScreen';
import KukaiMake from '../screens/KukaiMakeScreen';
import { RootStackParamList } from '../types';
import BottomTabNavigator from './BottomTabNavigator';
import LinkingConfiguration from './LinkingConfiguration';

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Root" component={BottomTabNavigator}  />
      <Stack.Screen name="Group" component={Group} options={{title: 'グループ'}} />
      <Stack.Screen name="Login" component={Login} options={{title: 'ログイン'}} />
      <Stack.Screen name="Join" component={Join} options={{title: 'グループ参加'}} />
      <Stack.Screen name="KukaiMake" component={KukaiMake} options={{title: '句会作成'}} />
      <Stack.Screen name="Post" component={Post} options={{title: '投句'}} />
      <Stack.Screen name="MyHaiku" component={MyHaiku} options={{title: '投句した俳句'}} />
      <Stack.Screen name="PDF" component={PDF} options={{title: 'PDF閲覧'}} />
      <Stack.Screen name="Text" component={Text} options={{title: 'テキスト閲覧'}} />
      <Stack.Screen name="OSS" component={OSS} options={{title: 'ノート'}} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
    </Stack.Navigator>
  );
}

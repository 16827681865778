import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          TabSetting: {
            screens: {
              TabSettingScreen: 'setting',
            },
          },
          TabKukai: {
            screens: {
              TabKukaiScreen: 'kukai',
            },
          },
          TabMembers: {
            screens: {
              TabMembersScreen: 'members',
            },
          },
        },
      },
      Group: 'group',
      Login: 'login',
      Join: 'join',
      KukaiMake: 'make',
      Post: 'post',
      MyHaiku: 'my',
      PDF: 'pdf',
      Text: 'text',
      OSS: 'oss',
      NotFound: '*',
    },
  },
};

import { StackScreenProps } from '@react-navigation/stack'
import * as React from 'react'
import { StyleSheet, TouchableOpacity, BackHandler, Clipboard , FlatList, ActivityIndicator, SafeAreaView, Alert, StatusBar, Platform } from 'react-native'
import AsyncStorage from '@react-native-community/async-storage'
import { Text, View, TextInput, Button } from '../components/Themed'
import { Ionicons } from '@expo/vector-icons'
import Toast from '../toast'

import { RootStackParamList, Haiku } from '../types'
import axios from 'axios'
import useColorScheme from '../hooks/useColorScheme'
let ios = true
if (Platform.OS === 'android') ios = false
interface PostData {
	i: string
	group: string
	at: string
	ku: Haiku[]
}

export default function MyHaikuScreen(props: StackScreenProps<RootStackParamList, 'MyHaiku'>) {
	React.useMemo(() => {
		Toast.hide()
		return
	}, [])
	const theme = useColorScheme()
	const { navigation, route } = props
	if (!route.params) {
		alert('Cannot show `MyHaikuScreen` because of no params:`data` to process initial actions')
		navigation.replace('Root', { screen: 'TabKukai' })
		return
	}
	const kukai = route.params.data
	BackHandler.addEventListener('hardwareBackPress', () => {
		navigation.replace('Root', { screen: 'TabKukai' })
		return true
	})
	const [ready, setReady] = React.useState(false)
	const [loading, setLoading] = React.useState(true)
	const [kus, setKus] = React.useState([] as Haiku[])
	const [edit, setEdit] = React.useState(-1)
	const [editLine, setEditLine] = React.useState('')
	const init = async () => {
		try {
			const provider = await AsyncStorage.getItem('provider')
			const iId = await AsyncStorage.getItem('id')
			const nowPrefix = await AsyncStorage.getItem('nowPrefix')
			const nowAt = await AsyncStorage.getItem('nowAt')
			if (provider && iId) {
				setReady(true)
				const kukais = await axios.post(`https://kukaibot.8i9.me/v1/kukai/${kukai.id}/my_haiku`, {
					i: iId,
					group: nowPrefix,
					at: nowAt
				})
				setKus(kukais.data.haiku)
				setLoading(false)
				return true
			}
			navigation.replace('Login')
			setReady(true)
		} catch {}
	}
	if (!ready) {
		init()
	}
	const renderItem = function (item: Haiku, index: number) {
		if (item.text == '') return null
		if (!kukai.write) {
			return (
				<View>
					<TouchableOpacity
						onLongPress={() => {
                            Clipboard.setString(item.text)
                            Toast.show('コピーしました', {
								containerStyle: { backgroundColor: theme == 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)', bottom: 12 },
								textStyle: { color: theme == 'dark' ? 'black' : 'white', fontSize: 13}
							})
						}}
                        activeOpacity={0.5}
                        style={{marginVertical: 5}}
					>
						<Text style={styles.title}>{item.text}</Text>
					</TouchableOpacity>
				</View>
			)
		}
		return (
			<View>
				<View style={styles.kukai}>
					<TouchableOpacity onPress={() => deleteThisKu(index)} activeOpacity={0.5}>
						<Ionicons name="md-trash" style={{ color: 'red', fontSize: 27, marginRight: 8 }} />
					</TouchableOpacity>
					{edit != index ? (
						<TouchableOpacity
							onPress={() => {
								setEdit(index)
								setEditLine(kus[index].text)
							}}
							activeOpacity={0.5}
						>
							<Text style={styles.title}>{item.text}</Text>
						</TouchableOpacity>
					) : (
						<View style={{ width: '95%' }}>
							<TextInput
								placeholder="俳句"
								style={styles.form}
								onChangeText={(text) => {
									setEditLine(text)
								}}
								value={editLine}
							/>
							<Button title="編集" onPress={() => editComplete(index, false)} materialIcon="edit" />
						</View>
					)}
				</View>
				<View style={styles.separator} lightColor="#d6d6d6" darkColor="rgba(255,255,255,0.1)" />
			</View>
		)
	}
	if (loading) {
		return (
			<View style={styles.container}>
				<ActivityIndicator size="large" color="#55cae0" />
			</View>
		)
	}
	return (
		<View style={styles.container}>
			<SafeAreaView style={{ flex: 0.08, flexDirection: 'row', marginVertical: 15, marginTop: ios ? 30 : 5, padding: 5  }}>
				<TouchableOpacity onPress={() => navigation.replace('Root', { screen: 'TabKukai' })}>
					<Ionicons name="md-arrow-back" style={{ color:'#858585',fontSize: 30, marginHorizontal: 8 }} />
				</TouchableOpacity>
				<Text style={[styles.title, {marginTop: ios ? 7 : 0}]}>自分が投句した俳句</Text>
			</SafeAreaView>
			
			<Text style={styles.title}>{kukai.name}</Text>
			<Text>{kukai.write ? '句をタップして編集' : '句を長押ししてコピー' }</Text>
			<SafeAreaView style={{ height: '60%',width: '95%' }}>
				<FlatList data={kus} renderItem={({ item, index }) => renderItem(item as Haiku, index)} keyExtractor={(item, index) => item.code} />
			</SafeAreaView>
		</View>
	)
	async function editComplete(num: number, del: boolean) {
		setEdit(-1)
		const kusClone = kus
		kusClone[num].text = editLine
		setLoading(true)
		const text = kusClone[num].text
		if(text === '') del = true
		const iId = await AsyncStorage.getItem('id')
		const nowPrefix = await AsyncStorage.getItem('nowPrefix')
		const nowAt = await AsyncStorage.getItem('nowAt')
		const challenge = await axios.post(`https://kukaibot.8i9.me/v1/kukai/${kukai.id}/edit`, {
			code: kusClone[num].code,
			haiku: text,
			delete: del,
			i: iId,
			group: nowPrefix,
			at: nowAt,
		})
		setLoading(false)
		if (challenge.data.status) alert(challenge.data.status)
		setKus(kusClone)
		setEditLine('')
		return true
	}

	function deleteThisKu(num: number) {
		Alert.alert(
			'句の削除',
			`"${kus[num].text}"を削除しますか？`,
			[
				{
					text: 'キャンセル',
					onPress: () => console.log(),
					style: 'cancel',
				},
				{
					text: 'OK',
					onPress: () => {
						editComplete(num, true)
					},
				},
			],
			{ cancelable: true }
		)
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 2,
		top: StatusBar.currentHeight,
		padding: 5,
	},
	title: {
		fontSize: 20,
		fontWeight: 'bold',
	},
	form: {
		marginVertical: 2,
		borderWidth: 1,
		width: '100%',
		padding: 10,
		borderRadius: 10,
	},
	textarea: {
		marginVertical: 2,
		borderWidth: 1,
		width: '95%',
		padding: 10,
		borderRadius: 1,
	},
	switch: {
		transform: [{ scaleX: 1.2 }, { scaleY: 1.2 }],
		marginRight: 5,
		position: 'relative',
		top: -3,
	},
	switches: {
		marginVertical: 10,
		flex: 0,
		flexDirection: 'row',
	},
	kukai: {
		padding: 15,
		marginBottom: 0,
		flex: 0,
		flexDirection: 'row',
		width: '95%',
	},
	separator: {
		height: 1,
		width: '100%',
	},
})

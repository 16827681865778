import * as React from 'react'
import RootSiblings from 'react-native-root-siblings'
import { Text, View, TouchableOpacity } from 'react-native'
import useColorScheme from './hooks/useColorScheme'
import { Ionicons } from '@expo/vector-icons'

let toast: RootSiblings
function show(message: string, options: any) {
	const theme = useColorScheme()
	toast = new RootSiblings(
		(
			<View style={{ backgroundColor: theme == 'dark' ? 'white' : '#4c4d4f', bottom: 50, position: 'absolute', height: 30, padding: 5, marginLeft: 10, flex: 1, flexDirection: 'row' }}>
				<Text style={{ color: theme == 'dark' ? 'black' : 'white' }}>{message}</Text>
				<TouchableOpacity style={{ backgroundColor: theme == 'dark' ? 'white' : '#4c4d4f', marginLeft: 5 }} onPress={() => hide()}>
					<Ionicons name="ios-close" style={{ color: theme == 'dark' ? 'black' : 'white', fontSize: 20 }} />
				</TouchableOpacity>
			</View>
		)
	)
	return toast
}
function hide() {
	if (toast) toast.destroy()
}
export default { show, hide, position: { CENTER: 0 } }

import { StackScreenProps } from '@react-navigation/stack'
import * as React from 'react'
import { StyleSheet, TouchableOpacity, BackHandler, Clipboard, Switch, FlatList, ActivityIndicator, SafeAreaView, StatusBar, Platform } from 'react-native'
import AsyncStorage from '@react-native-community/async-storage'
import { Text, View, TextInput, Button } from '../components/Themed'
import { Ionicons } from '@expo/vector-icons'
import Toast from '../toast'

import { RootStackParamList, Haiku } from '../types'
import axios from 'axios'
import useColorScheme from '../hooks/useColorScheme'
import { withSafeAreaInsets } from 'react-native-safe-area-context'
let ios = true
if (Platform.OS === 'android') ios = false

export default function TextScreen(props: StackScreenProps<RootStackParamList, 'Text'>) {
	React.useMemo(() => {
		Toast.hide()
		return
	}, [])
	const theme = useColorScheme()
	const { navigation, route } = props
	if (!route.params) {
		alert('Cannot show `TextScreen` because of no params:`data` to process initial actions')
		navigation.replace('Root', { screen: 'TabKukai' })
		return
	}
	const kukai = route.params.data
	BackHandler.addEventListener('hardwareBackPress', () => {
		navigation.replace('Root', { screen: 'TabKukai' })
		return true
	})
	const [ready, setReady] = React.useState(false)
	const [loading, setLoading] = React.useState(true)
	const [withNos, setWithNos] = React.useState(true)
	const [src, setSrc] = React.useState('')
	const [kus, setKus] = React.useState([] as Haiku[])
	const init = async () => {
		try {
			const provider = await AsyncStorage.getItem('provider')
			const iId = await AsyncStorage.getItem('id')
			const nowPrefix = await AsyncStorage.getItem('nowPrefix')
			const nowAt = await AsyncStorage.getItem('nowAt')
			if (provider && iId) {
				setReady(true)
				const kukais = await axios.post(`https://kukaibot.8i9.me/v1/kukai/${kukai.id}/haiku`, {
					i: iId,
					group: nowPrefix,
					at: nowAt,
				})
				setKus(kukais.data.haiku)
				setLoading(false)
				return true
			}
			navigation.replace('Login')
			setReady(true)
		} catch {}
	}
	if (!ready) {
		init()
	}
	const renderItem = function (item: Haiku, index: number) {
		if (src != '') {
			if (!~item.text.indexOf(src)) return null
		}
		return (
			<View>
				<TouchableOpacity
					onLongPress={() => {
						Clipboard.setString(item.text)
						Toast.show('コピーしました', {
							containerStyle: { backgroundColor: theme == 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)', bottom: 12 },
							textStyle: { color: theme == 'dark' ? 'black' : 'white', fontSize: 13 },
						})
					}}
					activeOpacity={0.5}
					style={{ marginVertical: 5 }}
				>
					<Text style={styles.title}>{item.text}</Text>
				</TouchableOpacity>
			</View>
		)
	}
	if (loading) {
		return (
			<View style={styles.container}>
				<ActivityIndicator size="large" color="#55cae0" />
			</View>
		)
	}
	return (
		<View style={styles.container}>
			<SafeAreaView style={{ flex: 0.08, flexDirection: 'row', marginVertical: 15, marginTop: ios ? 30 : 5, padding: 5 }}>
				<TouchableOpacity onPress={() => navigation.replace('Root', { screen: 'TabKukai' })}>
					<Ionicons name="md-arrow-back" style={{ color: '#858585', fontSize: 30, marginRight: 8 }} />
				</TouchableOpacity>
				<Text style={[styles.title, { marginTop: ios ? 7 : 0 }]}>テキスト閲覧</Text>
			</SafeAreaView>
			<Text style={styles.title}>{kukai.name}</Text>
			<View style={styles.switches}>
				<Switch trackColor={{ false: '#2a5c66', true: '#4598a8' }} thumbColor={'#4abad9'} ios_backgroundColor="#2a5c66" onValueChange={(tf) => setWithNos(tf)} value={withNos} style={styles.switch} />
				<TouchableOpacity onPress={() => (withNos ? setWithNos(false) : setWithNos(true))} activeOpacity={0.9} style={ios ? { paddingTop: 2 } : null}>
					<Text style={ios ? { fontSize: 17 } : null}>句の前に遠し番号(1. ...)を付与</Text>
				</TouchableOpacity>
			</View>
			<View style={{ width: '100%' }}>
				<Button title="テキストとしてコピー" onPress={() => makeTxt()} color="orange" icon="ios-copy" />
			</View>
			<Text>句を長押ししてコピー</Text>
			<TextInput
				style={styles.form}
				value={src}
				onChangeText={(text) => {
					setSrc(text)
				}}
				placeholder="検索"
			/>
			<SafeAreaView style={{ height: '60%', width: '95%' }}>
				<FlatList data={kus} renderItem={({ item, index }) => renderItem(item as Haiku, index)} keyExtractor={(item, index) => item.code} />
			</SafeAreaView>
		</View>
	)
	function makeTxt() {
		let toTxt = []
		let i = 0
		for (const txt of kus) {
			i++
			if (withNos) {
				toTxt.push(`${i}. ${txt.text}`)
			} else {
				toTxt.push(txt.text)
			}
		}
		Clipboard.setString(toTxt.join('\n'))
		Toast.show('コピーしました', {
			position: Toast.position.CENTER,
			componentAsImg: <Ionicons name="md-checkmark" size={50} style={{ color: theme == 'dark' ? 'black' : 'white' }} />,
			containerStyle: { backgroundColor: theme == 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)' },
			textStyle: { color: theme == 'dark' ? 'black' : 'white', fontSize: 20 },
		})
		return true
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 2,
		top: StatusBar.currentHeight,
		padding: 5,
	},
	title: {
		fontSize: 20,
		fontWeight: 'bold',
	},
	form: {
		marginVertical: 2,
		borderWidth: 1,
		width: '100%',
		padding: 10,
		borderRadius: 10,
	},
	textarea: {
		marginVertical: 2,
		borderWidth: 1,
		width: '100%',
		padding: 10,
		borderRadius: 1,
	},
	switch: {
		transform: [{ scaleX: ios ? 1 : 1.2 }, { scaleY: ios ? 1 : 1.2 }],
		marginRight: 5,
		position: 'relative',
		top: -3,
	},
	switches: {
		marginVertical: 10,
		flex: 0,
		flexDirection: 'row',
	},
	kukai: {
		padding: 15,
		marginBottom: 0,
		flex: 0,
		flexDirection: 'row',
		width: '95%',
	},
	separator: {
		height: 1,
		width: '100%',
	},
})

import { StackScreenProps } from '@react-navigation/stack'
import * as React from 'react'
import { StyleSheet, TouchableOpacity, BackHandler, Switch, FlatList, ActivityIndicator, SafeAreaView, Alert, StatusBar } from 'react-native'
import AsyncStorage from '@react-native-community/async-storage'
import { Text, View, TextInput, Button } from '../components/Themed'
import { Ionicons } from '@expo/vector-icons'
import Toast from '../toast'

import { RootStackParamList } from '../types'
import axios from 'axios'
interface PostData {
	i: string
	group: string
	at: string
	ku: string[]
}

export default function PostScreen(props: StackScreenProps<RootStackParamList, 'Post'>) {
	React.useMemo(() => {
		Toast.hide()
		return
	}, [])
	const { navigation, route } = props
	if (!route.params) {
		alert('Cannot show `PostScreen` because of no params:`data` to process initial actions')
		navigation.replace('Root', { screen: 'TabKukai' })
		return
	}
	const kukai = route.params.data
	BackHandler.addEventListener('hardwareBackPress', () => {
		navigation.replace('Root', { screen: 'TabKukai' })
		return true
	})
	const [ready, setReady] = React.useState(false)
	const [loading, setLoading] = React.useState(false)
	const [kus, setKus] = React.useState([] as string[])
	const [withLines, setWithLines] = React.useState(false)
	const [oneLine, setOneLine] = React.useState('')
	const [kusText, setKusText] = React.useState('')
	const [edit, setEdit] = React.useState(-1)
	const [editLine, setEditLine] = React.useState('')
	const init = async () => {
		try {
			setReady(true)
		} catch {}
	}
	if (!ready) {
		init()
	}
	const renderItem = function (item: string, index: number) {
		if (item == '') return null
		return (
			<View>
				<View style={styles.kukai}>
					<TouchableOpacity onPress={() => deleteThisKu(index)} activeOpacity={0.5}>
						<Ionicons name="md-trash" style={{ color: 'red', fontSize: 27, marginRight: 8 }} />
					</TouchableOpacity>
					{edit != index ? (
						<TouchableOpacity
							onPress={() => {
								setEdit(index)
								setEditLine(kus[index])
							}}
							activeOpacity={0.5}
						>
							<Text style={styles.title}>{item}</Text>
						</TouchableOpacity>
					) : (
						<View style={{ width: '95%' }}>
							<TextInput
								placeholder="俳句"
								style={styles.form}
								onChangeText={(text) => {
									setEditLine(text)
								}}
								value={editLine}
							/>
							<Button title="編集" onPress={() => editComplete(index)} materialIcon="edit" />
						</View>
					)}
				</View>
				<View style={styles.separator} lightColor="#d6d6d6" darkColor="rgba(255,255,255,0.1)" />
			</View>
		)
	}
	if (loading) {
		return (
			<View style={styles.container}>
				<ActivityIndicator size="large" color="#55cae0" />
			</View>
		)
	}
	return (
		<View style={styles.container}>
			<View style={{ flex: 0.08, flexDirection: 'row', marginVertical: 15 }}>
				<TouchableOpacity onPress={() => navigation.replace('Root', { screen: 'TabKukai' })}>
					<Ionicons name="md-arrow-back" style={{ color: '#858585', fontSize: 30, marginHorizontal: 8 }} />
				</TouchableOpacity>
				<Text style={styles.title}>投句</Text>
			</View>
			<Text>{kukai.name}</Text>
			<View style={styles.switches}>
				<Switch
					trackColor={{ false: '#2a5c66', true: '#4598a8' }}
					thumbColor={'#4abad9'}
					ios_backgroundColor="#2a5c66"
					onValueChange={(tf) => setWithLines(tf)}
					value={withLines}
					style={styles.switch}
				/>
				<TouchableOpacity onPress={() => (withLines ? setWithLines(false) : setWithLines(true))} activeOpacity={0.9}>
					<Text>句を一つずつ入力</Text>
				</TouchableOpacity>
			</View>
			{withLines ? (
				<View style={{ width: '95%' }}>
					<TextInput
						placeholder="俳句"
						style={styles.form}
						onChangeText={(text) => {
							setOneLine(text)
						}}
						value={oneLine}
					/>
					<Button title="追加" onPress={() => add()} />
				</View>
			) : (
				<TextInput
					placeholder="俳句(改行可能。行ごとに一句。)"
					style={styles.textarea}
					onChangeText={(text) => {
						setKus(text.split('\n'))
						setKusText(text)
					}}
					value={kusText}
					multiline={true}
					numberOfLines={2}
				/>
			)}
			<Text>句をタップして編集</Text>
			<SafeAreaView style={{ height: '60%', width: '95%' }}>
				<FlatList data={kus} renderItem={({ item, index }) => renderItem(item as string, index)} keyExtractor={(item, index) => `${index}`} />
			</SafeAreaView>
			<TouchableOpacity onPress={() => post()} style={[{ backgroundColor: '#2d61b5' }, styles.bottom]} activeOpacity={0.5}>
				<Ionicons name="ios-add" style={{ color: '#fff', fontSize: 34, marginRight: 8 }} />
				<Text style={[{ color: '#fff' }, styles.title]}>投句</Text>
			</TouchableOpacity>
		</View>
	)
	function add() {
		setEdit(-1)
		setOneLine('')
		const kusClone = kus
		kusClone.push(oneLine)
		setKus(kusClone)
		setKusText(kusClone.join('\n'))
	}
	function editComplete(num: number) {
		setEdit(-1)
		const kusClone = kus
		kusClone[num] = editLine
		setKus(kusClone)
		setKusText(kusClone.join('\n'))
		setEditLine('')
	}
	async function post() {
		setLoading(true)
		const iId = await AsyncStorage.getItem('id')
		const nowPrefix = await AsyncStorage.getItem('nowPrefix')
		const nowAt = await AsyncStorage.getItem('nowAt')
		if (!iId || !nowPrefix || !nowAt) return false
		if (!kus) {
			alert('句を入力してください')
			setLoading(false)
			return false
		}
		let cloneKu = []
		for (let ku of kus) {
			if (ku != '' && !ku.match(/^\s+$/)) cloneKu.push(ku)
			if (ku.match(/^[1１①]+/)) alert('句に通し番号を振ることは推奨されていません。')
		}

		const data: PostData = {
			i: iId,
			group: nowPrefix,
			at: nowAt,
			ku: cloneKu,
		}
		Alert.alert(
			'投句',
			`"${kukai.name}"に${cloneKu.length}句投句します。`,
			[
				{
					text: 'キャンセル',
					onPress: () => setLoading(false),
					style: 'cancel',
				},
				{
					text: 'OK',
					onPress: async () => {
						const challenge = await axios.post(`https://kukaibot.8i9.me/v1/kukai/${kukai.id}/post`, data)
						if (challenge.data.success) navigation.replace('Root', { screen: 'TabKukai' })
						setLoading(false)
						if (challenge.data.status) alert(challenge.data.status)
					},
				},
			],
			{ cancelable: true }
		)
	}

	function deleteThisKu(num: number) {
		setEdit(-1)
		Alert.alert(
			'句の削除',
			`"${kus[num]}"を削除しますか？`,
			[
				{
					text: 'キャンセル',
					onPress: () => console.log(),
					style: 'cancel',
				},
				{
					text: 'OK',
					onPress: () => {
						let newOne = []
						for (let i = 0; i < kus.length; i++) {
							if (i != num) newOne.push(kus[i])
						}
						setKus(newOne)
						setKusText(newOne.join('\n'))
					},
				},
			],
			{ cancelable: true }
		)
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 2,
        top: StatusBar.currentHeight,
	},
	title: {
		fontSize: 20,
		fontWeight: 'bold',
	},
	form: {
		marginVertical: 2,
		borderWidth: 1,
		width: '100%',
		padding: 10,
		borderRadius: 10,
	},
	textarea: {
		marginVertical: 2,
		borderWidth: 1,
		width: '95%',
		padding: 10,
		borderRadius: 1,
	},
	switch: {
		transform: [{ scaleX: 1.2 }, { scaleY: 1.2 }],
		marginRight: 5,
		position: 'relative',
		top: -3,
	},
	switches: {
		marginVertical: 30,
		flex: 0,
		flexDirection: 'row',
	},
	kukai: {
		padding: 15,
		marginBottom: 0,
		flex: 0,
		flexDirection: 'row',
		width: '95%',
		minHeight: 65
	},
	bottom: {
		position: 'absolute',
		padding: 15,
		bottom: 40,
		left: 0,
		flex: 0,
		flexDirection: 'row',
		width: '100%',
	},
	separator: {
		height: 1,
		width: '100%',
	},
})

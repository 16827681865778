import * as React from 'react'
import { StyleSheet, Platform, FlatList, SafeAreaView, TouchableOpacity, BackHandler, StatusBar, Alert } from 'react-native'
import AsyncStorage from '@react-native-community/async-storage'
import { Ionicons } from '@expo/vector-icons'
import { Text, View } from '../components/Themed'
import { StackScreenProps } from '@react-navigation/stack'
import useColorScheme from '../hooks/useColorScheme'
import Toast from '../toast'
import axios from 'axios'

import { RootStackParamList, Group } from '../types'

export default function TabGroupScreen(props: StackScreenProps<RootStackParamList, 'Group'>) {
	const { navigation, route } = props
	let backScreen = 'TabKukai'
	if (route.params) {
		backScreen = route.params.screen
	}
	BackHandler.addEventListener('hardwareBackPress', () => {
		navigation.replace('Root', { screen: backScreen })
		return true
	})
	React.useMemo(() => {
		Toast.hide()
		Toast.show('各グループを長押しするとメニューが表示されます', {
			containerStyle: { backgroundColor: theme == 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)', bottom: 12 },
			textStyle: { color: theme == 'dark' ? 'black' : 'white', fontSize: 13}
		})
		return
	}, [])
	//hash of android 5f:c0:35:e3:37:6e:4a:b4:92:5f:e3:74:5e:c3:c7:8c:36:96:42:37
	const [ready, setReady] = React.useState(false)
	const [selName, setSelName] = React.useState('')
	const [selId, setSelId] = React.useState('')
	const theme = useColorScheme()
	let themeAttr = 'menuItemLight' as 'menuItemLight' | 'menuItemDark'
	if (theme == 'dark') themeAttr = 'menuItemDark'
	const [showMenu, setShowMenu] = React.useState(false)
	const [id, setId] = React.useState('')
	const [group, setGroup] = React.useState([] as Group[])
	let ios = true
	if (Platform.OS === 'android') ios = false

	const tokenResponse = async () => {
		try {
			const provider = await AsyncStorage.getItem('provider')
			const iId = await AsyncStorage.getItem('id')
			const groupJson = await AsyncStorage.getItem('group')
			if (groupJson) setGroup(JSON.parse(groupJson))
			if (provider && iId) {
				setReady(true)
				setId(iId)
				if (groupJson) setGroup(JSON.parse(groupJson))
				return true
			}
			navigation.replace('Login')
		} catch {
			alert('error')
		}
	}
	if (!ready) {
		tokenResponse()
		return (
			<View style={styles.container}>
				<Text style={styles.title}>Loading...</Text>
			</View>
		)
	} else {
		if (!group.length) {
			navigation.replace('Join')
			return null
		} else {
			const renderItem = function (item: Group) {
				let joinned = 0
				if (item.joined) joinned = 1
				return (
					<View>
						<TouchableOpacity onPress={() => switchKukai(item.id)} onLongPress={() => menu(item.id, true)} style={styles.kukai} activeOpacity={0.5}>
							<Ionicons name="ios-checkmark" style={[{ opacity: joinned }, styles.checkIcon]} />
							<Text style={[styles.title, { marginTop: ios ? 7 : 0 }]}>{item.name ? item.name : item.id}</Text>
						</TouchableOpacity>
						<View style={styles.separator} lightColor="#d6d6d6" darkColor="rgba(255,255,255,0.1)" />
					</View>
				)
			}
			return (
				<SafeAreaView style={{ flex: 1, top: StatusBar.currentHeight }}>
					<SafeAreaView style={{ flex: 0.08, flexDirection: 'row', marginVertical: 15, marginTop: ios ? 30 : 5, padding: 5 }}>
						<TouchableOpacity onPress={() => navigation.replace('Root', { screen: backScreen })}>
							<Ionicons name="md-arrow-back" style={{ color: '#858585', fontSize: 30, marginHorizontal: 8 }} />
						</TouchableOpacity>
						<Text style={[styles.title, { marginTop: ios ? 7 : 0 }]}>グループ</Text>
					</SafeAreaView>
					<TouchableOpacity onPress={() => navigation.replace('Join')} style={[{ backgroundColor: '#2d61b5' }, styles.kukai]} activeOpacity={0.5}>
						<Ionicons name="ios-add" style={{ color: '#fff', fontSize: 34, marginRight: 8 }} />
						<Text style={[{ color: '#fff', marginTop: ios ? 8 : 0 }, styles.title]}>追加</Text>
					</TouchableOpacity>
					<SafeAreaView style={{ flex: 1 }}>
						<FlatList data={group} renderItem={({ item }) => renderItem(item as Group)} keyExtractor={(item) => item.id} />
					</SafeAreaView>
					{showMenu ? (
						<View style={styles.menu} darkColor={'rgba(0,0,0,0.8)'} lightColor={'rgba(255,255,255,0.9)'}>
							<Text style={{ fontSize: 35, fontWeight: 'bold' }}>{selName}</Text>
							<TouchableOpacity onPress={() => logout(selId)} style={styles[themeAttr]} activeOpacity={0.5}>
								<Text style={styles.title}>ログアウト</Text>
							</TouchableOpacity>
							<TouchableOpacity style={[styles[themeAttr], { backgroundColor: '#c9363b', borderColor: '#c9363b' }]} activeOpacity={0.5} onPress={() => quitGroup(selId)}>
								<Text style={[styles.title, { color: 'white' }]}>退会</Text>
							</TouchableOpacity>
							<TouchableOpacity onPress={() => setShowMenu(false)} style={styles[themeAttr]} activeOpacity={0.5}>
								<Text style={styles.title}>キャンセル</Text>
							</TouchableOpacity>
						</View>
					) : null}
				</SafeAreaView>
			)
		}
	}
	async function switchKukai(id: string) {
		await AsyncStorage.setItem('nowPrefix', id)
		let grp = group
		for (let ind of grp) {
			ind.joined = false
			if (ind.id == id) {
				ind.joined = true
				await AsyncStorage.setItem('nowAt', ind.at)
				await AsyncStorage.setItem('nowName', ind.name)
			}
		}
		const save = JSON.stringify(grp)
		setGroup(grp)
		await AsyncStorage.setItem('group', save)
		navigation.replace('Root', { screen: backScreen })
	}
	function menu(id: string, show: boolean) {
		let grp = group
		for (let ind of grp) {
			if (ind.id == id) {
				setSelName(ind.name)
				setSelId(ind.id)
			}
		}
		setShowMenu(show)
	}
	async function logout(id: string) {
		const nowJoin = await AsyncStorage.getItem('nowPrefix')
		if (group.length !== 1 && id === nowJoin) {
			alert('他のグループに切り替えてからこのグループをログアウトさせてください。')
			return false
		}
		Alert.alert(
			'ログアウト',
			`グループ"${selName}"からログアウトしますか？`,
			[
				{
					text: 'キャンセル',
					onPress: () => console.log(),
					style: 'cancel',
				},
				{
					text: 'OK',
					onPress: async () => {
						if (group.length === 1) {
							await AsyncStorage.removeItem('group')
							await AsyncStorage.removeItem('nowAt')
							await AsyncStorage.removeItem('nowPrefix')
							await AsyncStorage.removeItem('nowName')
							await AsyncStorage.removeItem('notfToken')
						} else {
							for (let i = 0; i < group.length; i++) {
								if (group[i].id === id) {
									group.splice(i, 1)
								}
							}
							const save = JSON.stringify(group)
							setGroup(group)
							await AsyncStorage.setItem('group', save)
						}
					},
				},
			],
			{ cancelable: true }
		)

		setShowMenu(false)
	}
	async function quitGroup(id: string) {
		const nowJoin = await AsyncStorage.getItem('nowPrefix')
		if (group.length !== 1 && id === nowJoin) {
			alert('他のグループに切り替えた後にこのグループから退会してください。')
			return false
		}
		Alert.alert(
			'退会',
			`グループ"${selName}"を退会します。そのグループ内のあなたに関するすべてのデータが削除されます。`,
			[
				{
					text: 'キャンセル',
					onPress: () => console.log(),
					style: 'cancel',
				},
				{
					text: 'OK',
					onPress: async () => {
						const nowPrefix = await AsyncStorage.getItem('nowPrefix')
						const nowAt = await AsyncStorage.getItem('nowAt')
						const iId = await AsyncStorage.getItem('id')
						await axios.post(`https://kukaibot.8i9.me/v1/member/${iId}/delete`, {
							i: iId,
							group: nowPrefix,
							at: nowAt,
							quitGroup: true,
						})
						if (group.length === 1) {
							await AsyncStorage.removeItem('group')
							await AsyncStorage.removeItem('nowAt')
							await AsyncStorage.removeItem('nowPrefix')
							await AsyncStorage.removeItem('nowName')
							await AsyncStorage.removeItem('notfToken')
						} else {
							for (let i = 0; i < group.length; i++) {
								if (group[i].id === id) {
									group.splice(i, 1)
								}
							}
							const save = JSON.stringify(group)
							setGroup(group)
							await AsyncStorage.setItem('group', save)
						}
					},
				},
			],
			{ cancelable: true }
		)

		setShowMenu(false)
	}
}
const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	title: {
		fontSize: 20,
		fontWeight: 'bold',
	},
	kukai: {
		padding: 15,
		marginBottom: 0,
		flex: 0.08,
		flexDirection: 'row',
		height: 65
	},
	checkIcon: { color: 'green', fontSize: 34, marginRight: 8 },
	separator: {
		height: 1,
		width: '100%',
	},
	menu: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		bottom: 0,
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	menuItemLight: {
		marginVertical: 2,
		borderWidth: 1,
		borderColor: 'black',
		width: 230,
		padding: 10,
		color: 'black',
		borderRadius: 10,
	},
	menuItemDark: {
		marginVertical: 2,
		borderWidth: 1,
		borderColor: 'white',
		width: 230,
		padding: 10,
		color: 'black',
		borderRadius: 10,
	},
})

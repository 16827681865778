import * as React from 'react'
import { Text as DefaultText, View as DefaultView, TextInput as DefaultTextInput, TouchableOpacity as DefaultTouchableOpacity, ButtonProps, StyleProp, ViewStyle } from 'react-native'

import Colors from '../constants/Colors'
import useColorScheme from '../hooks/useColorScheme'
import { Ionicons, MaterialIcons} from '@expo/vector-icons'

export function useThemeColor(props: { light?: string; dark?: string }, colorName: keyof typeof Colors.light & keyof typeof Colors.dark) {
	const theme = useColorScheme()
	const colorFromProps = props[theme]

	if (colorFromProps) {
		return colorFromProps
	} else {
		return Colors[theme][colorName]
	}
}

type ThemeProps = {
	lightColor?: string
	darkColor?: string
}
export type TextProps = ThemeProps & DefaultText['props']
export type ViewProps = ThemeProps & DefaultView['props']
export type TextInputProps = ThemeProps & DefaultTextInput['props']

export function Text(props: TextProps) {
	const { style, lightColor, darkColor, ...otherProps } = props
	const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text')

	return <DefaultText style={[{ color }, style]} {...otherProps} />
}

export function View(props: ViewProps) {
	const { style, lightColor, darkColor, ...otherProps } = props
	const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'background')

	return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />
}

export function TextInput(props: TextInputProps) {
	const { style, lightColor, darkColor, ...otherProps } = props
	const color = '#000'
	const backgroundColor = '#eee'
	const borderWidth = 2
	return <DefaultTextInput style={[{ color, backgroundColor, borderWidth }, style]} {...otherProps} />
}

export function TouchableOpacity(props: ViewProps) {
	const { style, lightColor, darkColor, ...otherProps } = props
	const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'background')
	return <DefaultTouchableOpacity activeOpacity={0.5} style={[{ backgroundColor }, style]} {...otherProps} />
}

interface ButtonExtendProps extends ButtonProps {
	style?: ViewStyle
  activeOpacity?: number
  icon?: string
  materialIcon?: string
}
export function Button(props: ButtonExtendProps) {
	const { title, color, style, icon, materialIcon,  ...otherProps } = props
	const base: ViewStyle = {
		borderRadius: 3,
		flexDirection: 'row',
		alignItems: 'center',
    justifyContent: 'center',
    padding: 10
	}
	return (
		<TouchableOpacity style={[{ backgroundColor: color ? color : '#3182eb' }, base, style]} {...otherProps}>
      {icon ? <Ionicons name={icon} style={{ color: '#fff', fontSize: 22, marginRight: 8 }} /> : null}
      {materialIcon ? <MaterialIcons name={materialIcon} style={{ color: '#fff', fontSize: 22, marginRight: 8 }} /> : null}
			<Text style={{ color: 'white', fontSize: 16 }}>{title}</Text>
		</TouchableOpacity>
	)
}

import * as React from 'react'
import Constants from 'expo-constants'
import { Ionicons } from '@expo/vector-icons'
import { StyleSheet, Button, TouchableOpacity, Platform, Linking } from 'react-native'
import AsyncStorage from '@react-native-community/async-storage'
import { Text, View, TextInput } from '../components/Themed'
import * as AppAuth from 'expo-app-auth'
import * as AppleAuthentication from 'expo-apple-authentication'
import { StackScreenProps } from '@react-navigation/stack'
import * as Notifications from 'expo-notifications'
import * as Permissions from 'expo-permissions'
import config from '../.config'
const isStandalone = Constants.appOwnership === 'standalone'
const ios = Platform.OS === 'ios'
const web = Platform.OS === 'web'

import axios from 'axios'
import { RootStackParamList } from '../types'
const configOfGoogle = {
	issuer: 'https://accounts.google.com',
	clientId: isStandalone ? config.GOOGLE_STANDALONE : config.GOOGLE_EXPO,
	scopes: ['profile'],
}
import Toast from '../toast'

export default function LoginScreen(props: StackScreenProps<RootStackParamList, 'Login'>) {
	React.useMemo(() => {
		Toast.hide()
		return
	}, [])
	const [continueCode, setContinueCode] = React.useState('')
	const [id, setId] = React.useState('')
	const { navigation, route } = props
	let code
	const [busy, setBusy] = React.useState(false)
	const login = async (provider: string, code?: string) => {
		setBusy(true)
		try {
			if (provider == 'continue') {
				if (!continueCode && !code) {
					alert('引き継ぎコードを入力してください')
					return false
				}
				const url = `https://kukaibot.8i9.me/v1/continue`
				const join = await axios.post(url, {
					code: code ? code : continueCode,
				})
				if (join.data.error) alert(join.data.status)
				if (join.data.onlyAuth) {
					const at = join.data.at
					if(join.data.provider == 'google') {
						const url = `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${at}`
						const profile = await axios.get(url)
						const { id } = profile.data
						await AsyncStorage.setItem('provider', 'google')
						await AsyncStorage.setItem('id', `google:${id}`)
						setId(id)
					}

					navigation.replace('Join')
					return true
				}
				const { i, prefix, name, haigo, isAdmin, isDev, at } = join.data
				if (!i) {
					alert(JSON.stringify(join.data))
					return false
				}
				await AsyncStorage.setItem('provider', 'continue')
				await AsyncStorage.setItem('id', i)
				await AsyncStorage.setItem('nowPrefix', prefix)
				await AsyncStorage.setItem('nowAt', at)
				setId(i)
				const grp = [
					{
						name: name,
						id: prefix,
						at: at,
						haigo: haigo,
						admin: isAdmin,
						dev: isDev,
						joined: true,
					},
				]
				const save = JSON.stringify(grp)
				await AsyncStorage.setItem('group', save)
				try {
					const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS)
					let finalStatus = existingStatus
					if (existingStatus !== 'granted') {
						const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS)
						finalStatus = status
					}
					if (finalStatus !== 'granted') {
						alert('通知を登録することができませんでした。「設定」より再試行できます。')
						navigation.replace('Root')
						return
					}
					const tokenR = await Notifications.getExpoPushTokenAsync()
					const token = tokenR.data
					await AsyncStorage.setItem('notfToken', token)
					await axios.post(`https://kukaibot.8i9.me/v1/setting/notification`, {
						i: i,
						group: prefix,
						at: at,
						target: 'notificationId',
						value: token,
						notificationId: null,
					})
				} catch {
					alert('通知を登録することができませんでした。「設定」より再試行できます。')
				}
				navigation.replace('Root')
			} else if (provider == 'google') {
				const authState = await AppAuth.authAsync(configOfGoogle)
				const at = authState.accessToken
				const url = `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${at}`
				const profile = await axios.get(url)
				const { id } = profile.data
				await AsyncStorage.setItem('provider', provider)
				await AsyncStorage.setItem('id', `${provider}:${id}`)
				setId(id)
				navigation.replace('Join')
			} else if (provider == 'apple') {
			}
		} catch ({ message }) {
			alert(`${message}`)
		}
	}
	if (busy) {
		return (
			<View style={styles.container}>
				<Text style={styles.title}>ログインを実行しています</Text>
			</View>
		)
	}
	if (route.params) {
		console.log(route.params)
		code = route.params.code
		setContinueCode(code)
		login('continue', code)
	}
	return (
		<View style={styles.container}>
			<Text style={styles.title}>ログイン</Text>
			{!web ? (
				<TouchableOpacity onPress={() => login('google')} style={[{ backgroundColor: '#2d61b5' }, styles.login]} activeOpacity={0.5}>
					<Ionicons style={styles.loginIcon} name="logo-google" />
					<Text style={styles.loginText}>Googleでログイン</Text>
				</TouchableOpacity>
			) : (
				<Button title="ログインする" onPress={() => Linking.openURL('https://kukaibot.8i9.me/login?from=app')} />
			)}
			{ios ? (
				<AppleAuthentication.AppleAuthenticationButton
					buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
					buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
					cornerRadius={10}
					style={[{ backgroundColor: '#000', height: 45 }, styles.login]}
					onPress={async () => {
						try {
							const credential = await AppleAuthentication.signInAsync({
								requestedScopes: [AppleAuthentication.AppleAuthenticationScope.FULL_NAME, AppleAuthentication.AppleAuthenticationScope.EMAIL],
							})
							console.log(credential)
							await AsyncStorage.setItem('provider', 'apple')
							await AsyncStorage.setItem('id', `apple:${credential.user}`)
							setId(credential.user)
							navigation.replace('Join')
							// signed in
						} catch (e) {
							if (e.code === 'ERR_CANCELED') {
								// handle that the user canceled the sign-in flow
							} else {
								// handle other errors
							}
						}
					}}
				/>
			) : null}

			<TextInput placeholder="引継ぎコード" onChangeText={(text) => setContinueCode(text)} style={styles.form} value={continueCode} />
			<Button title="引き継ぐ" onPress={() => login('continue')} />
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	title: {
		fontSize: 40,
		fontWeight: 'bold',
	},
	form: {
		height: 40,
		width: 230,
		padding: 8,
		borderColor: 'gray',
		borderWidth: 1,
		marginVertical: 15,
	},
	login: {
		marginVertical: 2,
		width: 230,
		padding: 10,
		color: '#fff',
		borderRadius: 10,
		flex: 0,
		flexDirection: 'row',
		height: 48,
	},
	loginText: { color: '#fff', fontSize: 18 },
	loginIcon: { color: '#fff', fontSize: 25, marginRight: 8 },
})

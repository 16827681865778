import * as React from 'react'

import { StyleSheet, ScrollView, TouchableOpacity, ActivityIndicator, Platform, Switch } from 'react-native'
import AsyncStorage from '@react-native-community/async-storage'
import * as Notifications from 'expo-notifications'
import * as Permissions from 'expo-permissions'
import Toast from '../toast'
import * as WebBrowser from 'expo-web-browser'

import { Text, View, TextInput, Button } from '../components/Themed'
import { StackScreenProps } from '@react-navigation/stack'
import useColorScheme from '../hooks/useColorScheme'

import axios from 'axios'
import { RootStackParamList, Settings } from '../types'
let ios = true
if (Platform.OS === 'android') ios = false

export default function TabSettingScreen({ navigation }: StackScreenProps<RootStackParamList, 'Root'>) {
	React.useMemo(() => {
		Toast.hide()
		return
	}, [])
	const theme = useColorScheme()
	const [ready, setReady] = React.useState(false)
	const [id, setId] = React.useState('')
	const [haigo, setHaigo] = React.useState('')
	const [code, setCode] = React.useState(null as null | string)
	const [prefix, setPrefix] = React.useState(null as null | string)
	const [withNotf, setWithNotf] = React.useState(false)
	const [newKukai, setNewKukai] = React.useState(false)
	const [kukaiEnd, setKukaiEnd] = React.useState(false)
	const [oneDay, setOneDay] = React.useState(false)
	const [tenMins, setTenMins] = React.useState(false)
	const [admin, setAdmin] = React.useState(false)
	const [busy, setBusy] = React.useState(false)
	const tokenResponse = async () => {
		try {
			const provider = await AsyncStorage.getItem('provider')
			const iId = await AsyncStorage.getItem('id')
			const nowPrefix = await AsyncStorage.getItem('nowPrefix')
			setPrefix(nowPrefix)
			const nowAt = await AsyncStorage.getItem('nowAt')
			const nowName = await AsyncStorage.getItem('nowName')
			const notfToken = await AsyncStorage.getItem('notfToken')
			if (!nowName) {
				navigation.replace('Group')
				return false
			}
			navigation.setOptions({
				headerRight: () => (
					<TouchableOpacity onPress={() => navigation.replace('Group', { screen: 'TabSetting' })} style={{ marginRight: 5 }}>
						<Text>{nowName}</Text>
					</TouchableOpacity>
				),
			})
			if (provider && iId) {
				setId(iId)
				const kukais = await axios.post(`https://kukaibot.8i9.me/v1/settings`, {
					i: iId,
					group: nowPrefix,
					at: nowAt,
					notificationId: notfToken,
				})
				const data = kukais.data as Settings
				setCode(data.invitationCode)
				setHaigo(data.haigo)
				setAdmin(data.admin)
				setWithNotf(data.notificationId ? true : false)
				setKukaiEnd(data.notification.oneDayBefore)
				setNewKukai(data.notification.newKukaiNotf)
				setTenMins(data.notification.tenMinsBefore)
				setOneDay(data.notification.oneDayBefore)

				setReady(true)
				return true
			}
			navigation.replace('Login')
		} catch {
			alert('error')
		}
	}
	if (!ready) {
		tokenResponse()
		return (
			<View style={styles.container}>
				<ActivityIndicator size="large" color="#55cae0" />
				<Text>設定項目を読み込んでいます</Text>
			</View>
		)
	} else {
		return (
			<ScrollView style={[styles.screen, { backgroundColor: theme == 'dark' ? 'black' : 'white' }]}>
				<Text style={styles.title}>俳号</Text>
				<View style={styles.rowTextInput}>
					<TextInput
						style={[{ borderColor: haigo ? 'black' : '#bf1313' }, styles.form]}
						value={haigo}
						onChangeText={(text) => {
							setHaigo(text)
						}}
						placeholder="俳号*"
					/>

					<View style={{ width: '18%', marginLeft: '2%' }}>
						<Button title="更新" onPress={() => updateHaigo()} color="orange" icon="md-refresh" />
					</View>
				</View>

				{code ? (
					<View style={{ marginBottom: 20 }}>
						<Text style={styles.title}>このグループの招待パスコード</Text>
						<View style={styles.rowTextInput}>
							<TextInput
								style={[{ borderColor: code ? 'black' : '#bf1313' }, styles.form]}
								value={code}
								onChangeText={(text) => {
									setCode(text)
								}}
								placeholder="招待コード*"
							/>
							{admin ? (
								<View style={{ width: '18%'}}>
									<Button title="更新" onPress={() => updateICode()} color="orange" icon="md-refresh" />
								</View>
							) : null}
						</View>
					</View>
				) : null}
				<Text style={styles.title}>通知</Text>
				<View style={[styles.switches, { marginBottom: 20 }]}>
					<Switch
						trackColor={{ false: '#66582a', true: '#f0ca4d' }}
						thumbColor={'#ffca1c'}
						ios_backgroundColor="#66582a"
						onValueChange={(tf) => subscribe(tf)}
						value={withNotf}
						style={styles.switch}
					/>
					<TouchableOpacity onPress={() => (withNotf ? subscribe(false) : subscribe(true))} activeOpacity={0.9} style={{ marginTop: ios ? 5 : 0 }}>
						<Text style={ios ? { fontSize: 17 } : null}>通知を受信</Text>
					</TouchableOpacity>
				</View>
				{withNotf ? (
					<View>
						<View style={styles.switches}>
							<Switch
								trackColor={{ false: '#2a5c66', true: '#4598a8' }}
								thumbColor={'#4abad9'}
								ios_backgroundColor="#2a5c66"
								onValueChange={(tf) => postSetting(tf, 'newKukai')}
								value={newKukai}
								style={styles.switch}
							/>
							<TouchableOpacity onPress={() => (newKukai ? postSetting(false, 'newKukai') : postSetting(true, 'newKukai'))} activeOpacity={0.9} style={{ marginTop: ios ? 5 : 0 }}>
								<Text style={ios ? { fontSize: 17 } : null}>新しい句会が作成されたときに通知する</Text>
							</TouchableOpacity>
						</View>
						<View style={styles.switches}>
							<Switch
								trackColor={{ false: '#2a5c66', true: '#4598a8' }}
								thumbColor={'#4abad9'}
								ios_backgroundColor="#2a5c66"
								onValueChange={(tf) => postSetting(tf, 'kukaiEnd')}
								value={kukaiEnd}
								style={styles.switch}
							/>
							<TouchableOpacity onPress={() => (kukaiEnd ? postSetting(false, 'kukaiEnd') : postSetting(true, 'kukaiEnd'))} activeOpacity={0.9} style={{ marginTop: ios ? 5 : 0 }}>
								<Text style={ios ? { fontSize: 17 } : null}>句会が締め切られたときに通知する</Text>
							</TouchableOpacity>
						</View>
						<Text>締切が設定されている(プレミアムグループ)ときに通知を受信できます</Text>
						<View style={styles.switches}>
							<Switch
								trackColor={{ false: '#2a5c66', true: '#4598a8' }}
								thumbColor={'#4abad9'}
								ios_backgroundColor="#2a5c66"
								onValueChange={(tf) => postSetting(tf, 'oneDay')}
								value={oneDay}
								style={styles.switch}
							/>
							<TouchableOpacity onPress={() => (oneDay ? postSetting(false, 'oneDay') : postSetting(true, 'oneDay'))} activeOpacity={0.9} style={{ marginTop: ios ? 5 : 0 }}>
								<Text style={ios ? { fontSize: 17 } : null}>24時間前に通知する</Text>
							</TouchableOpacity>
						</View>
						<View style={styles.switches}>
							<Switch
								trackColor={{ false: '#2a5c66', true: '#4598a8' }}
								thumbColor={'#4abad9'}
								ios_backgroundColor="#2a5c66"
								onValueChange={(tf) => postSetting(tf, 'tenMins')}
								value={tenMins}
								style={styles.switch}
							/>
							<TouchableOpacity onPress={() => (tenMins ? postSetting(false, 'tenMins') : postSetting(true, 'tenMins'))} activeOpacity={0.9} style={{ marginTop: ios ? 5 : 0 }}>
								<Text style={ios ? { fontSize: 17 } : null}>10分前に通知する</Text>
							</TouchableOpacity>
						</View>
					</View>
				) : null}
				<View style={{ marginVertical: 30 }}>
					<TouchableOpacity onPress={async () => await WebBrowser.openBrowserAsync(`https://kukaibot.8i9.me/login?from=edit:${prefix}`)} activeOpacity={0.5}>
						<Text style={styles.title}>その他設定(Web)</Text>
						<Text>グループ名やその他詳細設定</Text>
						<Text>(タップして移動)</Text>
					</TouchableOpacity>
				</View>
				<View style={{ marginVertical: 30 }}>
					<TouchableOpacity onPress={() => navigation.replace('OSS')} activeOpacity={0.5}>
						<Text style={styles.title}>ノート</Text>
						<Text>クレジット・連絡先・利用規約・プライバシーポリシー・オープンソースライセンス</Text>
						<Text>(タップして表示)</Text>
					</TouchableOpacity>
				</View>
				{busy ? (
					<View style={styles.menu} darkColor={'rgba(0,0,0,0.8)'} lightColor={'rgba(255,255,255,0.9)'}>
						<ActivityIndicator size="large" color="#55cae0" />
						<Text>設定を更新中</Text>
					</View>
				) : null}
			</ScrollView>
		)
	}
	async function subscribe(action: boolean) {
		setBusy(true)
		setWithNotf(action)
		try {
			let token = 'delete'
			await AsyncStorage.removeItem('notfToken')
			if (action) {
				const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS)
				let finalStatus = existingStatus
				if (existingStatus !== 'granted') {
					const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS)
					finalStatus = status
				}
				alert(finalStatus)
				if (finalStatus !== 'granted') {
					alert('権限が足りません')
					setWithNotf(false)
					setBusy(false)
					return
				}
				const tokenR = await Notifications.getExpoPushTokenAsync()
				token = tokenR.data
				await AsyncStorage.setItem('notfToken', token)
			}

			const nowAt = await AsyncStorage.getItem('nowAt')
			const iId = await AsyncStorage.getItem('id')
			const notfToken = await AsyncStorage.getItem('notfToken')
			console.log(notfToken)
			if (prefix && nowAt) {
				await axios.post(`https://kukaibot.8i9.me/v1/setting/notification`, {
					i: iId,
					group: prefix,
					at: nowAt,
					target: 'notificationId',
					value: token,
					notificationId: action ? null : notfToken,
				})
			}
			setBusy(false)
		} catch {
			alert('通知を登録/解除できませんでした')
			setWithNotf(action ? false : true)
			setBusy(false)
		}
	}
	async function postSetting(action: boolean, target: string) {
		setBusy(true)
		if (target === 'newKukai') setNewKukai(action)
		if (target === 'kukaiEnd') setKukaiEnd(action)
		if (target === 'oneDay') setOneDay(action)
		if (target === 'tenMins') setTenMins(action)
		const nowAt = await AsyncStorage.getItem('nowAt')
		const iId = await AsyncStorage.getItem('id')
		const notfToken = await AsyncStorage.getItem('notfToken')
		if (prefix && nowAt) {
			await axios.post(`https://kukaibot.8i9.me/v1/setting/notification`, {
				i: iId,
				group: prefix,
				at: nowAt,
				target: target,
				value: action,
				notificationId: notfToken,
			})
		}

		setBusy(false)
	}
	async function updateHaigo() {
		setBusy(true)
		if (!haigo) {
			alert('俳号は空欄にできません')
			return false
		}
		const nowAt = await AsyncStorage.getItem('nowAt')
		const iId = await AsyncStorage.getItem('id')
		if (prefix && nowAt) {
			await axios.post(`https://kukaibot.8i9.me/v1/setting/haigo`, {
				i: iId,
				group: prefix,
				at: nowAt,
				haigo: haigo,
			})
		}
		setBusy(false)
	}
	async function updateICode() {
		setBusy(true)
		if (!code) {
			alert('招待コードは空欄にできません')
			return false
		}
		const nowAt = await AsyncStorage.getItem('nowAt')
		const iId = await AsyncStorage.getItem('id')
		if (prefix && nowAt) {
			await axios.post(`https://kukaibot.8i9.me/v1/setting/invitation_code`, {
				i: iId,
				group: prefix,
				at: nowAt,
				code: code,
			})
		}
		setBusy(false)
	}
}
const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	title: {
		fontSize: 20,
		fontWeight: 'bold',
	},
	screen: {
		padding: 5,
		flex: 1,
	},
	form: {
		marginVertical: 2,
		borderWidth: 1,
		width: '80%',
		padding: 10,
		borderRadius: 10,
	},
	switch: {
		transform: [{ scaleX: ios ? 1 : 1.2 }, { scaleY: ios ? 1 : 1.2 }],
		marginRight: 5,
		position: 'relative',
		top: -3,
	},
	switches: {
		marginVertical: 10,
		flex: 0,
		flexDirection: 'row',
	},
	menu: {
		width: '105%',
		height: '100%',
		position: 'absolute',
		bottom: 0,
		left: 0,
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	rowTextInput: {
		flex: 0,
		flexDirection: 'row',
	},
})

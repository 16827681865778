//引っ張って更新
import * as React from 'react'
import Toast from '../toast'
import { StyleSheet, FlatList, SafeAreaView, TouchableOpacity, ActivityIndicator, Alert, RefreshControl, Platform } from 'react-native'
import * as WebBrowser from 'expo-web-browser'
import AsyncStorage from '@react-native-community/async-storage'

import { Ionicons } from '@expo/vector-icons'
import { Text, View, TextInput } from '../components/Themed'
import { StackScreenProps } from '@react-navigation/stack'
import useColorScheme from '../hooks/useColorScheme'

import axios from 'axios'
import moment from 'moment-timezone'
import 'moment/locale/ja'
moment.locale('ja')
import { RootStackParamList, Kukai } from '../types'
let ios = true
if (Platform.OS === 'android') ios = false

export default function TabKukaiScreen({ navigation }: StackScreenProps<RootStackParamList, 'Root'>) {
	const theme = useColorScheme()
	let themeAttr = 'menuItemLight' as 'menuItemLight' | 'menuItemDark'
	if (theme == 'dark') themeAttr = 'menuItemDark'
	React.useMemo(() => {
		Toast.hide()
		Toast.show('各句会を長押しするとオンライン選句(β)に移動できます', {
			containerStyle: { backgroundColor: theme == 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)', bottom: 12 },
			textStyle: { color: theme == 'dark' ? 'black' : 'white', fontSize: 13}
		})
		return
	}, [])
	const [ready, setReady] = React.useState(false)
	const [complete, setComplete] = React.useState(false)
	const [id, setId] = React.useState('')
	const [selectedData, setSelectedData] = React.useState({} as Kukai)
	const [showMenu, setShowMenu] = React.useState(false)
	const [kukai, setKukai] = React.useState([] as Kukai[])
	const [refreshing, setRefreshing] = React.useState(false)

	const onRefresh = React.useCallback(async () => {
		setRefreshing(true)
		await tokenResponse()
		setRefreshing(false)
	}, [])
	const tokenResponse = async () => {
		try {
			setReady(true)
			const provider = await AsyncStorage.getItem('provider')
			const iId = await AsyncStorage.getItem('id')
			const nowPrefix = await AsyncStorage.getItem('nowPrefix')
			const nowAt = await AsyncStorage.getItem('nowAt')
			const nowName = await AsyncStorage.getItem('nowName')
			if (!nowName) {
				navigation.replace('Group')
				return false
			}
			navigation.setOptions({
				headerRight: () => (
					<TouchableOpacity onPress={() => navigation.replace('Group', { screen: 'TabKukai' })} style={{ marginRight: 5 }}>
						<Text>{nowName}</Text>
					</TouchableOpacity>
				),
			})
			if (provider && iId) {
				if (!nowPrefix) navigation.replace('Join')
				setId(iId)
				const kukais = await axios.post(`https://kukaibot.8i9.me/v1/kukais`, {
					i: iId,
					group: nowPrefix,
					at: nowAt,
				})
				setKukai(kukais.data.kukai)
				if (kukais.data.error) alert(kukais.data.status)
				setComplete(true)
				return true
			}
			navigation.replace('Login')
		} catch (e) {
			console.error(e)
			alert('error')
		}
	}
	if (!complete) {
		if (!ready) tokenResponse()
		return (
			<View style={styles.container}>
				<Text style={styles.title}>
					<ActivityIndicator size="large" color="#55cae0" />
				</Text>
			</View>
		)
	} else {
		const renderItem = function (item: Kukai) {
			return (
				<View>
					<TouchableOpacity onPress={() => kukaiMenuScreen(item)} onLongPress={() => onSen(item, item.deletable)} style={styles.kukai} activeOpacity={0.5}>
						<Text style={styles.title}>{item.name}</Text>
						<View style={{ flex: 0, flexDirection: 'row' }}>
							{item.write ? <Text style={[{ backgroundColor: '#87a834', paddingTop: ios ? 5 : 0 }, styles.label]}>投句可能</Text> : null}
							{item.write && item.deadline ? <Text style={[{ backgroundColor: '#75344a' }, styles.label]}>締切:{moment(item.deadline).fromNow()}</Text> : null}
							{!item.write && !item.read ? <Text style={[{ backgroundColor: '#ba783a' }, styles.label]}>作成中</Text> : null}
							{item.write && item.onsen ? <Text style={[{ backgroundColor: '#1e2c9c' }, styles.label]}>オンライン選句予定</Text> : null}
							{!item.write && item.onsen && !item.onsenResult ? <Text style={[{ backgroundColor: '#3e499c' }, styles.label]}>選句受付中</Text> : null}
							{!item.write && item.onsenResult ? <Text style={[{ backgroundColor: '#535a94' }, styles.label]}>選句結果発表</Text> : null}
						</View>
					</TouchableOpacity>
					<View style={styles.separator} lightColor="#d6d6d6" darkColor="rgba(255,255,255,0.1)" />
				</View>
			)
		}

		return (
			<View style={{ flex: 1 }}>
				<TouchableOpacity onPress={() => navigation.replace('KukaiMake')} style={[{ backgroundColor: '#16751b', flex: 0.1, flexDirection: 'row', height: 65 }, styles.kukai]} activeOpacity={0.5}>
					<Ionicons name="ios-add" style={{ color: '#fff', fontSize: 34, marginRight: 8 }} />
					<Text style={[{ color: '#fff', marginTop: ios ? 8 : 0 }, styles.title]}>句会の作成</Text>
				</TouchableOpacity>
				<SafeAreaView style={{ flex: 1 }}>
					{kukai ? (
						<FlatList
							data={kukai}
							renderItem={({ item }) => renderItem(item as Kukai)}
							keyExtractor={(item) => `${item.id}`}
							refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
						/>
					) : (
						<Text>句会リストを読み込んでいます</Text>
					)}
				</SafeAreaView>
				{showMenu ? (
					<View style={styles.menu} darkColor={'rgba(0,0,0,0.8)'} lightColor={'rgba(255,255,255,0.9)'}>
						<Text style={{ fontSize: 35, fontWeight: 'bold' }}>{selectedData.name}</Text>
						<Text>{`あなたの${selectedData.count}`}</Text>
						<Text>{new Date(selectedData.createdAt).toLocaleString()}</Text>
						<TouchableOpacity style={styles[themeAttr]} activeOpacity={0.5} onPress={() => mainAcrivity()}>
							<Text style={styles.title}>{selectedData.write ? '投句' : selectedData.read ? 'PDF閲覧' : '作成状況'}</Text>
						</TouchableOpacity>
						{selectedData.read ? (
							<TouchableOpacity style={styles[themeAttr]} activeOpacity={0.5} onPress={() => navigation.replace('Text', { data: selectedData })}>
								<Text style={styles.title}>テキストで見る</Text>
							</TouchableOpacity>
						) : null}
						{selectedData.write ? (
							<TouchableOpacity style={styles[themeAttr]} activeOpacity={0.5} onPress={() => closeKukai()}>
								<Text style={styles.title}>締め切る</Text>
							</TouchableOpacity>
						) : null}
						<TouchableOpacity style={styles[themeAttr]} activeOpacity={0.5} onPress={() => navigation.replace('MyHaiku', { data: selectedData })}>
							<Text style={styles.title}>自分が投句した句</Text>
						</TouchableOpacity>
						{selectedData.deletable ? (
							<TouchableOpacity style={[styles[themeAttr], { backgroundColor: '#c9363b', borderColor: '#c9363b' }]} activeOpacity={0.5} onPress={() => deleteKukai()}>
								<Text style={[styles.title, { color: 'white' }]}>削除</Text>
							</TouchableOpacity>
						) : null}
						<TouchableOpacity onPress={() => setShowMenu(false)} style={styles[themeAttr]} activeOpacity={0.5}>
							<Text style={styles.title}>キャンセル</Text>
						</TouchableOpacity>
					</View>
				) : null}
			</View>
		)
	}
	async function kukaiMenuScreen(data: Kukai) {
		setSelectedData(data)
		setShowMenu(true)
		return
	}
	async function onSen(item: Kukai, deletable: boolean) {
		if (!deletable) {
			alert('オンライン選句は句会の作成者または管理者がオンにしなければ使えません')
			return false
		}
		setComplete(false)
		const nowPrefix = await AsyncStorage.getItem('nowPrefix')
		const nowAt = await AsyncStorage.getItem('nowAt')
		if (nowPrefix && nowAt) {
			const data = await axios.post(`https://kukaibot.8i9.me/v1/connect`, {
				i: id,
				group: nowPrefix,
				at: nowAt,
				id: item.id
			})
			const code = data.data.code
			if(!code && data.data.status) {
				alert(data.data.status)
				return false
			}
			setComplete(true)
			await WebBrowser.openBrowserAsync(`https://kukaibot.8i9.me/online/${nowPrefix}/${item.id}/menu?code=${code}`)
		}
	}
	function deleteKukai() {
		setComplete(false)
		Alert.alert(
			'句会の削除',
			`句会"${selectedData.name}"を削除しますか？`,
			[
				{
					text: 'キャンセル',
					onPress: () => setComplete(true),
					style: 'cancel',
				},
				{
					text: 'OK',
					onPress: async () => {
						const nowPrefix = await AsyncStorage.getItem('nowPrefix')
						const nowAt = await AsyncStorage.getItem('nowAt')
						if (nowPrefix && nowAt) {
							await axios.post(`https://kukaibot.8i9.me/v1/kukai/${selectedData.id}/delete`, {
								i: id,
								group: nowPrefix,
								at: nowAt,
							})
							setShowMenu(false)
							setComplete(true)
							setReady(false)
							await tokenResponse()
						} else {
							setComplete(true)
						}
					},
				},
			],
			{ cancelable: true }
		)
	}
	function mainAcrivity() {
		if (selectedData.write) {
			//投句
			navigation.replace('Post', { data: selectedData })
		} else {
			if (selectedData.read) {
				//閲覧
				navigation.replace('PDF', { data: selectedData })
			} else {
			}
		}
	}
	async function closeKukai() {
		Alert.alert(
			'句会を締め切る',
			`句会"${selectedData.name}"を締め切りますか？`,
			[
				{
					text: 'キャンセル',
					onPress: () => setShowMenu(false),
					style: 'cancel',
				},
				{
					text: 'OK',
					onPress: async () => {
						setComplete(false)
						const nowPrefix = await AsyncStorage.getItem('nowPrefix')
						const nowAt = await AsyncStorage.getItem('nowAt')
						if (nowPrefix && nowAt) {
							await axios.post(`https://kukaibot.8i9.me/v1/kukai/${selectedData.id}/close`, {
								i: id,
								group: nowPrefix,
								at: nowAt,
							})
							alert('しばらく(1分程度)してから、この画面を上から引っ張って再読み込みしてください。')
							setShowMenu(false)
							setComplete(true)
							setReady(false)
							await tokenResponse()
						} else {
							setComplete(true)
						}
					},
				},
			],
			{ cancelable: true }
		)
	}
}
const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	title: {
		fontSize: 20,
		fontWeight: 'bold',
	},
	kukai: {
		padding: 15,
		marginBottom: 0,
		height: 75
	},
	checkIcon: { color: 'green', fontSize: 34, marginRight: 8 },
	separator: {
		height: 1,
		width: '100%',
	},
	label: {
		padding: ios ? 5 : 1,
		paddingHorizontal: 5,
		borderRadius: 5,
		marginHorizontal: 5,
		marginTop: ios ? 5 : 0,
		color: 'white',
		height: 30
	},
	menu: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		bottom: 0,
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	menuItemLight: {
		marginVertical: 2,
		borderWidth: 1,
		borderColor: 'black',
		width: 230,
		padding: 10,
		color: 'black',
		borderRadius: 10,
	},
	menuItemDark: {
		marginVertical: 2,
		borderWidth: 1,
		borderColor: 'white',
		width: 230,
		padding: 10,
		color: 'black',
		borderRadius: 10,
	},
})

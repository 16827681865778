import { Ionicons } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { Button } from 'react-native'

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import TabKukaiScreen from '../screens/TabKukaiScreen';
import TabMembersScreen from '../screens/TabMembersScreen';
import TabSettingScreen from '../screens/TabSettingScreen';
import { BottomTabParamList, TabKukaiParamList, TabMembersParamList,TabSettingParamList } from '../types';

const BottomTab = createBottomTabNavigator<BottomTabParamList>();

export default function BottomTabNavigator() {
  const colorScheme = useColorScheme();

  return (
    <BottomTab.Navigator
      initialRouteName="TabKukai"
      tabBarOptions={{ activeTintColor: Colors[colorScheme].tint }}>
      <BottomTab.Screen
        name="TabKukai"
        component={TabKukaiNavigator}
        options={{
          title: '句会',
          tabBarIcon: ({ color }) => <TabBarIcon name="ios-create" color={color} />,
        }}
      />
       <BottomTab.Screen
        name="TabMembers"
        component={TabMembersNavigator}
        options={{
          title: 'メンバー',
          tabBarIcon: ({ color }) => <TabBarIcon name="md-people" color={color} />,
        }}
      />
      <BottomTab.Screen
        name="TabSetting"
        component={TabSettingNavigator}
        options={{
          title: '設定',
          tabBarIcon: ({ color }) => <TabBarIcon name="md-settings" color={color} />,
        }}
      />
    </BottomTab.Navigator>
  );
}

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon(props: { name: string; color: string }) {
  return <Ionicons size={30} style={{ marginBottom: -3 }} {...props} />;
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab

const TabKukaiStack = createStackNavigator<TabKukaiParamList>();

function TabKukaiNavigator() {
  return (
    <TabKukaiStack.Navigator>
      <TabKukaiStack.Screen
        name="TabKukaiScreen"
        component={TabKukaiScreen}
        options={{ headerTitle: '句会に参加' }}
      />
    </TabKukaiStack.Navigator>
  );
}

const TabMembersStack = createStackNavigator<TabMembersParamList>();

function TabMembersNavigator() {
  return (
    <TabMembersStack.Navigator>
      <TabMembersStack.Screen
        name="TabMembersScreen"
        component={TabMembersScreen}
        options={{ headerTitle: 'メンバー一覧' }}
      />
    </TabMembersStack.Navigator>
  );
}
const TabSettingStack = createStackNavigator<TabSettingParamList>();
function TabSettingNavigator() {
  return (
    <TabSettingStack.Navigator>
      <TabSettingStack.Screen
        name="TabSettingScreen"
        component={TabSettingScreen}
        options={{ headerTitle: '設定' }}
      />
    </TabSettingStack.Navigator>
  );
}

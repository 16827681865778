import * as React from 'react'
import { StyleSheet, Button, BackHandler, ActivityIndicator, SafeAreaView, TouchableOpacity, Platform, StatusBar } from 'react-native'
import * as WebBrowser from 'expo-web-browser'
import AsyncStorage from '@react-native-community/async-storage'
import { Text, View, TextInput } from '../components/Themed'
import { StackScreenProps } from '@react-navigation/stack'
import * as Notifications from 'expo-notifications'
import * as Permissions from 'expo-permissions'
import Toast from '../toast'

import axios from 'axios'
import { RootStackParamList, Group } from '../types'
import { Ionicons } from '@expo/vector-icons'
import useColorScheme from '../hooks/useColorScheme'
let ios = true
if (Platform.OS === 'android') ios = false

export default function JoinScreen({ navigation }: StackScreenProps<RootStackParamList, 'Join'>) {
	const theme = useColorScheme()
	BackHandler.addEventListener('hardwareBackPress', () => {
		navigation.replace('Group', { screen: 'TabKukai' })
		return true
	})
	React.useMemo(() => {
		Toast.hide()
		Toast.show('過去に参加済みのグループの場合、ここで指定した俳号は更新されません。', {
			containerStyle: { backgroundColor: theme == 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)', bottom: 12 },
			textStyle: { color: theme == 'dark' ? 'black' : 'white', fontSize: 13 },
		})
		return
	}, [])
	const [ready, setReady] = React.useState(false)
	const [busy, setBusy] = React.useState(false)
	const [id, setId] = React.useState('')
	const [group, setGroup] = React.useState([] as Group[])
	const [groupId, setGroupId] = React.useState('')
	const [groupPwd, setGroupPwd] = React.useState('')
	const [groupHaigo, setGroupHaigo] = React.useState('')
	const tokenResponse = async () => {
		try {
			const provider = await AsyncStorage.getItem('provider')
			const iId = await AsyncStorage.getItem('id')
			const group = await AsyncStorage.getItem('group')
			if (group) setGroup(JSON.parse(group))
			if (iId) {
				setReady(true)
				return true
			}
			navigation.replace('Login')
		} catch {
			alert('error')
		}
	}
	if (!ready) tokenResponse()
	if (busy) {
		return (
			<View style={styles.container}>
				<ActivityIndicator size="large" color="#55cae0" />
				<Text>設定を書き込んでいます</Text>
			</View>
		)
	}
	return (
		<View style={{height: '100%', top: StatusBar.currentHeight}}>
			<SafeAreaView style={{ flex: 0.08, flexDirection: 'row', marginVertical: 15, marginTop: ios ? 30 : 5, padding: 5  }}>
				<TouchableOpacity onPress={() => navigation.replace('Root', { screen: 'Group' })}>
					<Ionicons name="md-arrow-back" style={{ color: '#858585', fontSize: 30, marginHorizontal: 8 }} />
				</TouchableOpacity>
				<Text style={[styles.title, { marginTop: ios ? 7 : 0 }]}>グループに参加</Text>
			</SafeAreaView>
			<View style={styles.container}>
				<Text style={styles.title}>グループに参加する</Text>
				<Text>*は必須項目</Text>
				<TextInput placeholder="ID(グループプレフィクス)*" onChangeText={(text) => setGroupId(text)} style={[{borderColor: groupId ? 'black' : '#bf1313'}, styles.form]} value={groupId} />
				<TextInput placeholder="招待コード*" onChangeText={(text) => setGroupPwd(text)} style={[{borderColor: groupPwd ? 'black' : '#bf1313'}, styles.form]} value={groupPwd} />
				<Text>俳号</Text>
				<TextInput placeholder="俳号*" onChangeText={(text) => setGroupHaigo(text)} style={[{borderColor: groupHaigo ? 'black' : '#bf1313'}, styles.form]} value={groupHaigo} />
				<View style={{ transform: [{ scaleX: 1.2 }, { scaleY: 1.2 }], marginVertical: 30 }}>
					<Button title="参加する" onPress={join} />
					<View style={{ height: 20 }} />
					{group.length ? <Button title="キャンセル" color="red" onPress={() => navigation.replace('Root', { screen: 'TabGroup' })} /> : null}
					<Button title="新規作成" onPress={async() => await WebBrowser.openBrowserAsync('https://kukaibot.8i9.me/login?from=new')} />
				</View>
				<Button title="新規作成" onPress={() => Linking.openURL('https://kukaibot.8i9.me/login?from=new')} />
			</View>
		</View>
	)
	async function join() {
		setBusy(true)
		const i = await AsyncStorage.getItem('id')
		if (!groupId || !groupHaigo || !groupPwd) {
			alert('必須項目を埋めてください。')
			return false
		}
		try {
			const join = await axios.post('https://kukaibot.8i9.me/v1/join', {
				i: i,
				id: groupId,
				name: groupHaigo,
				pwd: groupPwd,
			})

			if (join.data.error || !join.data.name) {
				alert(join.data.status)
				setBusy(false)
				return false
			}
			let grp = [] as Group[]
			let joinNow = true
			if (group.length) {
				grp = group
				joinNow = false
			} else {
				await AsyncStorage.setItem('nowPrefix', groupId)
				await AsyncStorage.setItem('nowAt', join.data.at)
				await AsyncStorage.setItem('nowName', join.data.name)
			}
			grp.push({
				name: join.data.name,
				id: groupId,
				at: join.data.at,
				haigo: groupHaigo,
				admin: join.data.isAdmin,
				dev: join.data.isDev,
				joined: joinNow,
			})
			const save = JSON.stringify(grp)
			setGroup(grp)
			await AsyncStorage.setItem('group', save)
			try {
				let token = await AsyncStorage.getItem('notfToken')
				if (!token) {
					const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS)
					let finalStatus = existingStatus
					if (existingStatus !== 'granted') {
						const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS)
						finalStatus = status
					}
					if (finalStatus !== 'granted') {
						alert('通知を登録することができませんでした。「設定」より再試行できます。')
						navigation.replace('Root')
						return
					}
					const tokenR = await Notifications.getExpoPushTokenAsync()
					token = tokenR.data
					await AsyncStorage.setItem('notfToken', token)
				}
				await axios.post(`https://kukaibot.8i9.me/v1/setting/notification`, {
					i: i,
					group: groupId,
					at: join.data.at,
					target: 'notificationId',
					value: token,
					notificationId: null,
				})
			} catch {
				alert('通知を登録することができませんでした。「設定」より再試行できます。')
			}
			navigation.replace('Group', { screen: 'TabKukai' })
		} catch {
			setBusy(false)
			alert('error')
		}
	}
}
const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	title: {
		fontSize: 20,
		fontWeight: 'bold',
	},
	form: {
		marginVertical: 2,
		borderWidth: 1,
		width: 250,
		padding: 10,
		borderRadius: 10,
	},
})

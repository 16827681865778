import * as React from 'react'
import { StyleSheet, FlatList, SafeAreaView, TouchableOpacity, ActivityIndicator, Alert, Image, Platform, RefreshControl } from 'react-native'
import AsyncStorage from '@react-native-community/async-storage'

import { Ionicons } from '@expo/vector-icons'
import { Text, View, TextInput } from '../components/Themed'
import { StackScreenProps } from '@react-navigation/stack'
import useColorScheme from '../hooks/useColorScheme'
import Toast from '../toast'

import axios from 'axios'
import { RootStackParamList, Members, Member } from '../types'
let ios = true
if (Platform.OS === 'android') ios = false

export default function TabMembersScreen({ navigation }: StackScreenProps<RootStackParamList, 'Root'>) {
	React.useMemo(() => {
		Toast.hide()
		return
	}, [])
	const theme = useColorScheme()
	let themeAttr = 'menuItemLight' as 'menuItemLight' | 'menuItemDark'
	if (theme == 'dark') themeAttr = 'menuItemDark'
	const [ready, setReady] = React.useState(false)
	const [id, setId] = React.useState('')
	const [selectedData, setSelectedData] = React.useState({} as Member)
	const [showMenu, setShowMenu] = React.useState(false)
	const [members, setMembers] = React.useState([] as Member[])
	const [admin, setAdmin] = React.useState(false)
	const [refreshing, setRefreshing] = React.useState(false)

	const onRefresh = React.useCallback(async () => {
		setRefreshing(true)
		await tokenResponse()
		setRefreshing(false)
	}, [])
	const tokenResponse = async () => {
		try {
			const provider = await AsyncStorage.getItem('provider')
			const iId = await AsyncStorage.getItem('id')
			const nowPrefix = await AsyncStorage.getItem('nowPrefix')
			const nowAt = await AsyncStorage.getItem('nowAt')
			const nowName = await AsyncStorage.getItem('nowName')
			if (!nowName) {
				navigation.replace('Group')
				return false
			}
			navigation.setOptions({
				headerRight: () => (
					<TouchableOpacity onPress={() => navigation.replace('Group', { screen: 'TabMembers' })} style={{ marginRight: 5 }}>
						<Text>{nowName}</Text>
					</TouchableOpacity>
				),
			})
			if (provider && iId) {
				setId(iId)
				const kukais = await axios.post(`https://kukaibot.8i9.me/v1/members`, {
					i: iId,
					group: nowPrefix,
					at: nowAt,
				})
				const data = kukais.data as Members
				setMembers(data.members)
				setAdmin(data.admin)
				setReady(true)
				return true
			}
			navigation.replace('Login')
		} catch {
			alert('error')
		}
	}
	if (!ready) {
		tokenResponse()
		return (
			<View style={styles.container}>
				<ActivityIndicator size="large" color="#55cae0" />
				<Text>メンバーリストを読み込んでいます</Text>
			</View>
		)
	} else {
		const renderItem = function (item: Member) {
			return (
				<View>
					<TouchableOpacity onPress={() => (admin ? kukaiMenuScreen(item) : false)} style={styles.kukai} activeOpacity={admin ? 0.9 : 1}>
						<Text style={styles.title}>{item.name}</Text>
						{item.profile.displayName ? (
							<View style={{ flex: 0, flexDirection: 'row', backgroundColor: '#8aad7b', height: 20 }}>
								{item.profile.pictureUrl ? <Image source={{ uri: item.profile.pictureUrl }} style={{ width: 20 }} /> : <Ionicons name="ios-add" style={{ color: '#fff', fontSize: 20 }} />}
								<Text style={{ color: 'white', marginLeft: 5, marginTop: ios ? 2 : 0 }}>{item.profile.displayName}</Text>
							</View>
						) : (
							<View style={{ flex: 0, flexDirection: 'row', backgroundColor: '#b3b3b3' }}>
								<Ionicons name="ios-warning" style={{ color: '#fff', fontSize: 20, marginLeft: 3 }} />
								<Text style={{ color: 'white', marginLeft: 5, marginTop: ios ? 3 : 0 }}>未設定</Text>
							</View>
						)}
					</TouchableOpacity>
					<View style={styles.separator} lightColor="#d6d6d6" darkColor="rgba(255,255,255,0.1)" />
				</View>
			)
		}
		return (
			<View style={{ flex: 1 }}>
				<SafeAreaView style={{ flex: 1 }}>
					{members ? (
						<FlatList
							data={members}
							renderItem={({ item }) => renderItem(item as Member)}
							keyExtractor={(item) => `${item.userId}`}
							refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
						/>
					) : (
						<Text>メンバーリストを読み込んでいます</Text>
					)}
				</SafeAreaView>
				{showMenu ? (
					<View style={styles.menu} darkColor={'rgba(0,0,0,0.8)'} lightColor={'rgba(255,255,255,0.9)'}>
						<Text style={{ fontSize: 35, fontWeight: 'bold' }}>{selectedData.name}</Text>
						<TouchableOpacity style={styles[themeAttr]} activeOpacity={0.5} onPress={() => toggleAdmin()}>
							<Text style={styles.title}>管理者{selectedData.admin ? 'から外す' : 'にする'}</Text>
						</TouchableOpacity>
						<TouchableOpacity style={[styles[themeAttr], { backgroundColor: '#c9363b', borderColor: '#c9363b' }]} activeOpacity={0.5} onPress={() => deleteMember()}>
							<Text style={[styles.title, { color: 'white' }]}>削除</Text>
						</TouchableOpacity>
						<TouchableOpacity onPress={() => setShowMenu(false)} style={styles[themeAttr]} activeOpacity={0.5}>
							<Text style={styles.title}>キャンセル</Text>
						</TouchableOpacity>
					</View>
				) : null}
			</View>
		)
	}
	async function kukaiMenuScreen(data: Member) {
		setSelectedData(data)
		setShowMenu(true)
		return
	}
	function deleteMember() {
		Alert.alert(
			'句会の削除',
			`メンバー"${selectedData.name}"を削除しますか？`,
			[
				{
					text: 'キャンセル',
					onPress: () => console.log(),
					style: 'cancel',
				},
				{
					text: 'OK',
					onPress: async () => {
						const nowPrefix = await AsyncStorage.getItem('nowPrefix')
						const nowAt = await AsyncStorage.getItem('nowAt')
						if (nowPrefix && nowAt) {
							await axios.post(`https://kukaibot.8i9.me/v1/member/${selectedData.userId}/delete`, {
								i: id,
								group: nowPrefix,
								at: nowAt,
								quitGroup: false,
							})
							setShowMenu(false)
							setReady(false)
						}
					},
				},
			],
			{ cancelable: true }
		)
	}
	function toggleAdmin() {
		Alert.alert(
			'句会の削除',
			`メンバー"${selectedData.name}"を管理者${selectedData.admin ? 'から外し' : 'にし'}ますか？`,
			[
				{
					text: 'キャンセル',
					onPress: () => console.log(),
					style: 'cancel',
				},
				{
					text: 'OK',
					onPress: async () => {
						const nowPrefix = await AsyncStorage.getItem('nowPrefix')
						const nowAt = await AsyncStorage.getItem('nowAt')
						if (nowPrefix && nowAt) {
							await axios.post(`https://kukaibot.8i9.me/v1/member/${selectedData.userId}/admin/${selectedData.admin ? 'remove' : 'add'}`, {
								i: id,
								group: nowPrefix,
								at: nowAt,
							})
							setShowMenu(false)
							setReady(false)
						}
					},
				},
			],
			{ cancelable: true }
		)
	}
}
const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	title: {
		fontSize: 20,
		fontWeight: 'bold',
	},
	kukai: {
		padding: 15,
		marginBottom: 0,
	},
	checkIcon: { color: 'green', fontSize: 34, marginRight: 8 },
	separator: {
		height: 1,
		width: '100%',
	},
	label: {
		padding: 1,
		paddingHorizontal: 5,
		borderRadius: 5,
		marginHorizontal: 5,
		color: 'white',
	},
	menu: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		bottom: 0,
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	menuItemLight: {
		marginVertical: 2,
		borderWidth: 1,
		borderColor: 'black',
		width: 230,
		padding: 10,
		color: 'black',
		borderRadius: 10,
	},
	menuItemDark: {
		marginVertical: 2,
		borderWidth: 1,
		borderColor: 'white',
		width: 230,
		padding: 10,
		color: 'black',
		borderRadius: 10,
	},
})
